import axios from 'axios'
import config from '../config.json';

class ApiServices {

    handleOperationWithRetryUserInput(serverCall, retryUserInput, successCallback, moreInfoCollect, failureCallback, operationName) {
        serverCall.then((response) => {
            this.logSuccessAndCallback(response, successCallback, operationName);
        }).catch((ex) => {
            console.log(operationName + " Failed", ex);
            this.handleException(ex, moreInfoCollect, failureCallback, (userInputs, operationId, retryOperationName) => {
                retryUserInput(this.getRetryHeaders(operationId, userInputs, retryOperationName))
                .then((response) => {
                    this.logSuccessAndCallback(response, successCallback, operationName + " With User Inputs");
                }).catch((ex) => {
                    console.log(operationName + " With User Inputs Failed", ex);
                    failureCallback(ex);
                });
            });
        });
    }

    getRetryHeaders(operationId, userInputs, operationName){
        return {
            headers:{
                "Retry-Opeation": Buffer.from(JSON.stringify({
                    "userInputs": userInputs,
                    "operationId": operationId,
                    "operationName": operationName
                })).toString('base64')
            }
        };
    }

    handleException(ex, moreInfoCollect, failureCallback, retryCallback) {
        var response = ex.response;
        if(response != null){
            if(response.status === 400){
                var responseData = response.data;
                if(responseData != null){
                    if(responseData.hasOwnProperty("errorType")){
                        var errorType = responseData.errorType;
                        var payload = responseData.payload;
                        if(errorType === "DapiUserInputRequired"){
                            var operationId = payload.operationId;
                            var userInputs = payload.userInputs;
                            var retryOperationName = payload.operationName;
                            moreInfoCollect(userInputs, operationId, retryOperationName, (userInputs, operationId) => {
                                retryCallback(userInputs, operationId, retryOperationName);
                            });
                            return;
                        }
                    }
                }
            }
        }
        failureCallback(ex);
    }

    handleOperationNoRetry(serverCall, successCallback, failureCallback, operationName) {
        serverCall.then((response) => {
            this.logSuccessAndCallback(response, successCallback, operationName);
        }).catch((ex) => {
            this.handleExceptionNoRetry(ex, failureCallback, operationName);
        });
    }

    logSuccessAndCallback(response, successCallback, operation) {
        console.log(operation + " Success", response);
        successCallback(response);
    }

    handleExceptionNoRetry(ex, failureCallback, operation) {
        console.log(operation + " Failed", ex);
        failureCallback(ex);
    }

    performPostOperationWithRetryUserInput(url, postObject, successCallback, moreInfoCollect, failureCallback, operationName) {
        this.handleOperationWithRetryUserInput(
            axios.post(config.apiUrl + url, postObject),
            (retryHeader) => {
                return axios.post(config.apiUrl + url, postObject, retryHeader)
            },
            successCallback, moreInfoCollect, failureCallback, operationName);
    }

    performGetOperationWithRetryUserInput(url, successCallback, moreInfoCollect, failureCallback, operationName) {
        this.handleOperationWithRetryUserInput(
            axios.get(config.apiUrl + url), 
            (retryHeader) => {
                return axios.get(config.apiUrl + url, retryHeader)
            },
            successCallback, moreInfoCollect, failureCallback, operationName);
    }

    performPostOperationNoRetry(url, postObject, successCallback, failureCallback, operationName) {
        this.handleOperationNoRetry(axios.post(config.apiUrl + url, postObject), 
            successCallback, failureCallback, operationName);
    }

    performPutOperationNoRetry(url, putObject, successCallback, failureCallback, operationName) {
        this.handleOperationNoRetry(axios.put(config.apiUrl + url, putObject), 
            successCallback, failureCallback, operationName);
    }

    performPostOperationFormDataNoRetry(url, formData, successCallback, failureCallback, operationName) {
        this.handleOperationNoRetry(axios({
            method: "post",
            url: config.apiUrl + url,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }), 
            successCallback, failureCallback, operationName);
    }

    performPostOperationNoBodyNoRetry(url, successCallback, failureCallback, operationName) {
        this.handleOperationNoRetry(axios.post(config.apiUrl + url), 
            successCallback, failureCallback, operationName);
    }

    performGetOperationNoRetry(url, successCallback, failureCallback, operationName) {
        // const cors = require("cors");
        var options = {
            // headers: {
            //     "Access-Control-Allow-Origin": "*",
            //     mode: "x-srf",               
            //     },
            withCredentials: true};
        console.log(operationName + " options", options);
        this.handleOperationNoRetry(axios.get(config.apiUrl + url, options), 
            successCallback, failureCallback, operationName);
    }

    performGetOperationNoRetryWithAdditionalHeaders(url, additionalHeaders, successCallback, failureCallback, operationName) {
        this.handleOperationNoRetry(axios.get(config.apiUrl + url, {
                headers: additionalHeaders
            }), 
            successCallback, failureCallback, operationName);
    }

    performGetOperationNoRetryWithParams(url, params, successCallback, failureCallback, operationName) {
        this.handleOperationNoRetry(axios.get(config.apiUrl + url, {
                params: params
            }), 
            successCallback, failureCallback, operationName);
    }

}

const services = new ApiServices();
  
export default services;
